// all bootstrap customizations such as link colors, variables
/* You can add global styles to this file, and also import other style files */

html {
  height: 100%;
}

@font-face {
  font-family: TitilliumWeb;
  src: url(../font/Titillium_Web/TitilliumWeb-Regular.ttf) format("opentype");
}

body {
  font-family: TitilliumWeb, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
     "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  /* font-family: 'Montserrat', sans-serif; */
  font-weight: lighter;
  font-size: 16px;
  height: 100%;
}

body.modal-open {
  overflow: hidden !important;
}

/* colori customizzati */
.bg-blusid {
  background-color: #0059a6;
}

.text-blusid {
  color: #0059a6;
}

.text-danger {
  color: #a94442 !important;
}

.txt-white {
  color: #fff !important;
}

.txt-bg-blu {
  color: #00377b !important;
}

.btn-blusid {
  color: #fff;
  background-color: #0059a6;
  border-color: #0059a6;
}

.colmin {
  width: 1px;
}

.modalFull95 .modal-dialog {
  max-width: 95%;
  height: 90vh;
}

.modalFull75 .modal-dialog {
  max-width: 75%;
  height: 90vh;
}

.modalFull50 .modal-dialog {
  max-width: 50%;
  height: 90vh;
}

.modalFull90 .modal-dialog {
  max-width: 50%;
  height: 80%;
  /* Siamo sicuri che non sia vh? */
  max-height: calc(100vw - 500px);
}

.margin10 {
  padding-top: 20px;
}

.modalFull5040 .modal-dialog {
  max-width: 40%;
  height: 30vh;
  max-height: 200px;
}

.modal5095 .modal-dialog {
  max-width: 95%;
  height: 50vh;
}

.pointer {
  cursor: pointer;
}

.no-outline {
  outline: none !important;
  box-shadow: none !important;
}
/* Aggiunge la linea verde sul top del tab selezionato*/
.nav-link.active,
.nav-item.show .nav-link {
  border-top-color: green;
  border-top-width: 2px;
}

.background-color-white {
  background-color: #ffffff;
}

.background-color-lightgrey {
  background-color: #efefef;
}

.form-control {
  color: #000000;
}

::placeholder {
  color: #ced4da;
  opacity: 1;
}
.custom-modal .modal-content {
  height: 100%;
  width: 100%;
}
.custom-modal-profilo-economico .modal-content {
  height: 100%;
  width: 100%;
  /* padding-top: 40px; */
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  text-decoration: underline;
}
.modal-xl {
  max-width: 90%;
}

[hidden] {
  display: none !important;
}

/* Background */
.bg-blu {
  background-color: #00377b !important;
}

.bg-blu_light {
  background-color: #2079d4;
}

.bg-dark-blue {
  background-color: #003366;
}

/* center */
.center {
  margin: auto;
  display: block;
}

/* width rem */

.w-6rem {
  width: 6rem;
}

/* margin */

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.m-5 {
  margin: 5px !important;
}
/* padding*/

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}
/* height */

.h-70vh {
  height: 70vh;
}

.h-75vh {
  height: 75vh;
}

.btn-bg-outline {
  background: #fff;
  color: #0059a6;
  border: 1px solid #0059a6;
}
// .btn-bg-outline:focus,
// .btn-bg-outline:hover {
//    color: white;
//    background-color: #484b4d;
// }
/* scrollbar css */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* end scrollbar css */

/* custom css paginator */

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0066cc;
  border-color: #0066cc;
  font-weight: bold;
}

/* STYLE MODAL */

.modal-content {
  border: none;

  .modal-header {
     button:focus {
        outline: none;
        box-shadow: none;
     }
  }

  .modal-footer {
    //  button {
    //     background-color: #0066cc;
    //     color: #fff;
    //     border-color: transparent;

    //     &.btn-warning {
    //        color: #212529;
    //        background-color: #ffc107;
    //        border-color: #ffc107;
    //     }

    //     &.btn-danger {
    //        color: #fff;
    //        background-color: #dc3545;
    //        border-color: #dc3545;
    //     }
    //  }

     .btn-bg-outline {
        background: #fff !important;
        color: #0059a6 !important;
        border: 1px solid #0059a6 !important;
     }
     // .btn-bg-outline:focus,
     // .btn-bg-outline:hover {
     //    color: white !important;
     //    background-color: #484b4d !important;
     // }
  }
}

.patient-alert-danger {
  background-color: #ef5350 !important;
  color: #ffffff !important;
}

.patient-alert-success {
  background-color: #4caf50 !important;
  color: #ffffff !important;
}

ngb-typeahead-window {
  z-index: 1100 !important;
}

// id del primo autocomplete del menu, serve ad allinearlo all'input
#ngb-typeahead-0 {
  // left: 10rem !important;
  transform: translate(13px, 62px) !important;
}

.txt-center {
  text-align: center;
}
